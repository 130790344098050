class ExpandingPill extends HTMLElement {
  constructor() {
    super();

    this.open = true;
    this.isTouchDevice = window.matchMedia("(hover: none)").matches;
  }

  connectedCallback() {
    this.textEl = this.querySelector(".expanding-pill-text");
    this.addEventListeners();
    this.collapseAfterDelay();
  }

  disconnectedCallback() {
    this.removeEventListeners();
  }

  addEventListeners() {
    this.addEventListener("click", this.handleClick);
  }

  removeEventListeners() {
    this.removeEventListener("click", this.handleClick);
  }

  collapseAfterDelay() {
    setTimeout(() => {
      this.collapse();
    }, 3000);
  }

  handleClick(e) {
    e.preventDefault();
    if (this.isTouchDevice) {
      if (this.open) {
        if (this.eventToEmit) {
          window.dispatchEvent(new Event(this.eventToEmit));
        }
        if (this.analyticsEvent) {
          analytics.track(this.analyticsEvent);
        }
      } else {
        this.expand();
      }
    }
  }

  expand() {
    this.open = true;
    this.textEl.classList.toggle("expanded", this.open);
    this.collapseAfterDelay();
  }

  collapse(e) {
    if (e) {
      e.preventDefault();
    }
    this.open = false;
    this.textEl.classList.toggle("expanded", this.open);
  }

  get eventToEmit() {
    return this.getAttribute("event-to-emit");
  }

  get analyticsEvent() {
    return this.getAttribute("analytics-event");
  }
}

customElements.define("expanding-pill", ExpandingPill);
